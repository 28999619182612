const addToDL = data => {
  window.dataLayer.push(data);
};
const qsAll = (sel: string): NodeList => document.querySelectorAll(sel);
const qs = (sel: string): HTMLElement | null => document.querySelector(sel);

const waitForElement = selector => {
  return new Promise(resolve => {
    if (qs(selector)) {
      return resolve(qs(selector));
    }
    const observer = new MutationObserver(() => {
      if (qs(selector)) {
        resolve(qs(selector));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const addDatalayerEvents = () => {
  if (window.Bus) {
    window.Bus.on('*', (type, e) => {
      // Find Clinic by Location
      if (type === 'emu-location-services:prompt') {
        addToDL({
          event: 'findClinicUseMyLocationClicked',
        });
      }
      // Find Clinic by Search Term
      if (
        type === 'emu-location-services:submitSearch' ||
        type === 'emu-location-services:locationSelect'
      ) {
        addToDL({
          event: 'findClinicSearchPerformed',
          searchTerm: e.address,
        });
      }
      // Find Clinic Module
      if (type === 'emu-location-services:countrySelect') {
        addToDL({
          event: 'findClinicModule',
          moduleText: e.country,
        });
      }
      // FAQ
      if (type === 'emu-accordion:toggle' && !e.itemClicked.opened) {
        addToDL({
          event: 'faqModule',
          moduleTitle: e.itemClicked.title,
        });
      }
      // Video Play
      if (type === 'emu-video-embed:play') {
        addToDL({
          event: 'videoPlay',
          videoTitle: e.title,
        });
      }
      // Video Paused
      if (type === 'emu-video-embed:pause') {
        addToDL({
          event: 'videoPaused',
          videoTitle: e.title,
        });
      }
      // Video Complete
      if (type === 'emu-video-embed:end') {
        addToDL({
          event: 'videoComplete',
          videoTitle: e.title,
        });
      }
    });
  }

  //  Find Clinic by Quick Search
  qsAll('.c-search-location__btn-container a').forEach(button => {
    const buttonEl = button as HTMLButtonElement;
    buttonEl.addEventListener('click', () => {
      addToDL({
        event: 'findClinicQuickSearchLinkClicked',
        quickSearchLinkTitle: buttonEl.dataset.emuTitle,
      });
    });
  });

  // Clinics List
  if (qs('.clinics-page') || qs('#clinics-page')) {
    waitForElement('.clinics__list .aaaem-card').then(() => {
      qsAll('.clinics__list .aaaem-card').forEach(card => {
        const clinicCard = card as HTMLElement;
        const clinicTitleEl = clinicCard.querySelector(
          '.item__heading'
        ) as HTMLElement;
        const clinicName = clinicTitleEl?.innerText;
        const websiteBtn = clinicCard.querySelector(
          '.item__link-website'
        ) as HTMLButtonElement;
        const phoneBtn = clinicCard.querySelector(
          '.item__link-phone'
        ) as HTMLButtonElement;
        const clinicBtn = clinicCard.querySelector(
          '.item__link-clinic'
        ) as HTMLButtonElement;
        // website
        websiteBtn?.addEventListener('click', () => {
          addToDL({
            event: 'visitClinicWebsite',
            clinicName: clinicName,
          });
        });
        // phone
        phoneBtn?.addEventListener('click', () => {
          addToDL({
            event: 'clinicCallClinic',
            clinicName: clinicName,
          });
        });

        // to clinics page
        clinicBtn?.addEventListener('click', () => {
          addToDL({
            event: 'clinicVisitClinicPage',
            clinicName: clinicName,
          });
        });
      });
    })
  }

  // Clinic Page
  if (qs('#clinic-details-page')) {
    waitForElement('.icl-detail__container').then(elm => {
      const clinicDetails = elm as HTMLElement;
      if (clinicDetails) {
        const clinicTitleEl = clinicDetails.querySelector(
          '.icl-detail__title'
        ) as HTMLElement;
        const clinicName = clinicTitleEl?.innerText;
        const websiteBtns = clinicDetails.querySelectorAll(
          '.icl-detail__button--website'
        );
        const phoneBtns = clinicDetails.querySelectorAll(
          '.icl-detail__button--phone'
        );
        const emailBtns = clinicDetails.querySelectorAll(
          '.icl-detail__button--email'
        );
        // website
        websiteBtns?.forEach(btn => {
          btn.addEventListener('click', () => {
            addToDL({
              event: 'visitClinicWebsite',
              clinicName: clinicName,
            });
          });
        });
        // phone
        phoneBtns?.forEach(btn => {
          btn.addEventListener('click', () => {
            addToDL({
              event: 'clinicCallClinic',
              clinicName: clinicName,
            });
          });
        });
        // email
        emailBtns?.forEach(btn => {
          btn.addEventListener('click', () => {
            addToDL({
              event: 'clinicEmailClinic',
              clinicName: clinicName,
            });
          });
        });
      }
    });
  }

  // Find Clinic Footer
  const footerFACBtns = qsAll('footer a[href*="find-a-clinic"]');
  footerFACBtns?.forEach(el => {
    el?.addEventListener('click', () => {
      addToDL({
        event: 'findClinicFooter',
      });
    });
  });
  
  // Find Clinic Footer (CA only)
  const caFooterFACBtns = qsAll('footer a[href*="locator.allerganaesthetics.ca"]');
  caFooterFACBtns?.forEach(el => {
    el?.addEventListener('click', () => {
      addToDL({
        event: 'findClinicFooter',
      });
    });
  });

  // Find Clinic Navigation
  const navFACBtn = qs(
    '.juvederm-navigation a[href*="find-a-clinic"]'
  ) as HTMLElement;
  navFACBtn?.addEventListener('click', () => {
    addToDL({
      event: 'findClinicNav',
    });
  });

  // Find Clinic Navigation (CA only)
  const caNavFACBtn = qs(
    '.juvederm-navigation a[href*="locator.allerganaesthetics.ca"]'
  ) as HTMLElement;
  caNavFACBtn?.addEventListener('click', () => {
    addToDL({
      event: 'findClinicNav',
    });
  });

  // Gender Model Selector (V2)
  const treatmentTabs = qsAll('.juvederm-treatmentareas .aaaem-tabs__tab a');
  treatmentTabs?.forEach(tab => {
    tab.addEventListener('click', () => {
      const tabEl = tab as HTMLAnchorElement;
      addToDL({
        event: 'genderModel',
        genderType: tabEl.dataset.emuItemTitle,
      });
    });
  });

  // Learn More Treatment
  const treatmentAreas = qs('.juvederm-treatmentareas-tabs');
  if (treatmentAreas) {
    const learnMoreCTAs = treatmentAreas.querySelectorAll('a[href*="treatment"]');
    learnMoreCTAs?.forEach(cta => {
      const treatmentTitle = cta.closest('.container')?.querySelector('h3');
      cta.addEventListener('click', () => {
        addToDL({
          event: 'learnMoretreatment',
          treatmentTitle: treatmentTitle?.innerText || treatmentTitle?.textContent,
        });
      });
    })
  }

  // Social
  const socialLinks = qsAll('footer a[href*="instagram"], footer a[href*="facebook"]');
  socialLinks?.forEach(link => {
    const linkEl = link as HTMLAnchorElement;
    linkEl.addEventListener('click', () => {
      addToDL({
        event: 'socialFooter',
        clickText: linkEl.href?.split('.')[1],
      });
    });
  });

  // Phase 1 Analytics ==========
  if (qs('#juvederm-phase1')) {
    // Gender Model Selector
    const treatmentTabs = qsAll('.juvp1-treatment-areas .aaaem-tabs__tab a');
    treatmentTabs?.forEach(tab => {
      tab.addEventListener('click', () => {
        const tabEl = tab as HTMLAnchorElement;
        addToDL({
          event: 'genderModel',
          genderType: tabEl.dataset.emuItemTitle,
        });
      });
    });
    // Find Clinic CTA
    const fACBtns = qsAll(
      '.juvp1-clinic-tout a, .juvp1-clinic-tout button'
    );
    fACBtns?.forEach(button => {
      button.addEventListener('click', () => {
        addToDL({
          event: 'findClinicCta',
        });
      });
    });

    // Treatment Features Card
    const keyFeaturesCards = qsAll('.juvp1-key-features__card-button');
    keyFeaturesCards?.forEach(card => {
      const cardEl = card as HTMLButtonElement;
      cardEl.addEventListener('click', () => {
        addToDL({
          event: 'treatmentFeatureCard',
          cardTitle: cardEl.dataset.toggleOffText,
        });
      });
    });

    // Face Pin Selector
    window.Bus.on('emu-image-map:areaClick', ({ title }) => {
      addToDL({
        event: 'facePinSelector',
        faceArea: title,
      });
    });

    // Social
    const socialLinksPhase1 = qsAll('.juvp1-footer__social a');
    socialLinksPhase1?.forEach(link => {
      const linkEl = link as HTMLAnchorElement;
      linkEl.addEventListener('click', () => {
        addToDL({
          event: 'socialFooter',
          clickText: linkEl.href?.split('.')[1],
        });
      });
    });
  }

  // Age Pop Up 18+
  const ageVerificationOver18Btns = qsAll('a.age-verification-over-18');
  ageVerificationOver18Btns?.forEach(el => {
    el?.addEventListener('click', () => {
      addToDL({
        event: 'agebanner',
        userAge: 'Over 18'
      });
    });
  });

  // Age Pop Up Under 18
  const ageVerificationUnder18Btns = qsAll('a.age-verification-under-18');
  ageVerificationUnder18Btns?.forEach(el => {
    el?.addEventListener('click', () => {
      addToDL({
        event: 'agebanner',
        userAge: 'Under 18'
      });
    });
  });
};

// Persist the Over 18+ Datalayer events on every page
window.onload = function() {
  if (window.localStorage && window.localStorage.getItem("emu-modal:age-verification-confirm-clicked")) {
    addToDL({
      event: 'agebanner',
      userAge: 'Over 18'
    });
  }
};