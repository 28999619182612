export const teaserInit = () => {
  const title = document.querySelector(
    '.cmp-teaser__description > h1'
  ) as HTMLHeadingElement;

  const handleTitleParallax = () => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        window.addEventListener('scroll', updateTitlePosition);
      } else {
        window.removeEventListener('scroll', updateTitlePosition);
      }
    });

    observer.observe(title);
  }

  const updateTitlePosition = () => {
    title.style.transform = `translateY(-${window.pageYOffset / 4}px)`;
  }

  if (title) {
    handleTitleParallax();
  }
}