import { jsPDF } from 'jspdf';
import { pdfText } from './pdfText';
import { gambettaVariable } from './fonts/gambettaVariableBase64';
import { satoshiLight } from './fonts/satoshiLightBase64';
import { satoshiBold } from './fonts/satoshiBoldBase64';
import type * as Types from './createPdf.types';

export const createPdf = async (
  selectedAreas: Types.BtnConfig[],
  selectedGoals: { btnInnerText: string; }[],
  uploadedImages: Record<string, string>,
  selectedGender: 'female' | 'male'
): Promise<void> => {
  const doc = new jsPDF({ unit: 'pt' });
  const { width, height } = doc.internal.pageSize;
  const FONT_GAMBETTA_VARIABLE = 'Gambetta-Variable';
  const FONT_SATOSHI_LIGHT = 'Satoshi-Light';
  const FONT_SATOSHI_BOLD = 'Satoshi-Bold';
  const SELECTED_GENDER_IMAGE_WIDTH = 135;
  let currentY = 0;
  let globalImageHeight: number;

  const PDF_CONFIG: Types.PDFConfig = {
    margin: { left: 85, right: 85 },
    backgroundColor: [71, 13, 74],
    line: { color: [203, 201, 197], width: 0.5 },
    text: { color: [71, 13, 74], size: 7 },
    title: { color: [255, 255, 255], size: 18 },
    sectionTitle: { x: 247, color: [71, 13, 74], size: 9 },
    headerHeight: 75,
    footerHeight: 290,
    incrementStep: 10,
    imageGap: 5
  };

  const emotionTextMap: Types.EmotionTextMap = {
    happy: pdfText.emotionHappy,
    neutral: pdfText.emotionNeutral,
    angry: pdfText.emotionAngry
  };

  const imagePaths: Types.ImagePaths = {
    female: '/content/dam/juvederm-ous/fr/consultation-wizard-female.jpg',
    male: '/content/dam/juvederm-ous/fr/consultation-wizard-male.jpg'
  };

  const setupFont = (fontFile: string, fontName: string, base64Font: string) => {
    doc.addFileToVFS(fontFile, base64Font);
    doc.addFont(fontFile, fontName, 'normal');
  };

  const setupFonts = (): void => {
    setupFont('Gambetta-Variable.ttf', FONT_GAMBETTA_VARIABLE, gambettaVariable);
    setupFont('Satoshi-Light.ttf', FONT_SATOSHI_LIGHT, satoshiLight);
    setupFont('Satoshi-Bold.ttf', FONT_SATOSHI_BOLD, satoshiBold);
  };

  const incrementY = (increment: number = PDF_CONFIG.incrementStep): void => {
    currentY += increment;
  };

  const setFontStyle = (color: number[], fontSize: number, font?: string): void => {
    const [r, g, b] = color;

    if (font) {
      doc.setFont(font);
    }

    doc.setFontSize(fontSize).setTextColor(r, g, b);
  };

  const addBackground = (x: number, y: number, height: number): void => {
    const [r, g, b] = PDF_CONFIG.backgroundColor;
    doc.setFillColor(r, g, b);
    doc.rect(x, y, width, height, 'F');
  };

  const addTitle = (title: string, x: number, color: number[], fontSize: number, options = {}): void => {
    setFontStyle(color, fontSize, FONT_GAMBETTA_VARIABLE);
    doc.text(title, x, currentY, options);
  };

  const addLine = (): void => {
    doc.setDrawColor(...PDF_CONFIG.line.color);
    doc.setLineWidth(PDF_CONFIG.line.width);
    doc.line(PDF_CONFIG.sectionTitle.x, currentY, width - PDF_CONFIG.margin.right, currentY);
  };

  const addSectionTitleWithLine = (title: string, x: number, titleColor: number[], fontSize: number): void => {
    addLine();
    incrementY(15);
    setFontStyle(titleColor, fontSize, FONT_SATOSHI_BOLD);
    doc.text(title, x, currentY);
    incrementY(15);
  };

  const getEmotionText = (key: string): string => {
    return emotionTextMap[key] || '';
  };

  const centerTextBelowImage = (text: string, imageX: number, imageWidth: number): void => {
    setFontStyle(PDF_CONFIG.text.color, PDF_CONFIG.text.size, FONT_SATOSHI_LIGHT);
    const textWidth = doc.getTextWidth(text);
    doc.text(text, imageX + (imageWidth - textWidth) / 2, currentY + imageWidth + 15);
  };

  const processImage = (
    imagePath: string,
    scaleFactor: number,
    quality: number
  ): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject(new Error('Could not create canvas context'));
          return;
        }

        const targetWidth = img.width * scaleFactor;
        const targetHeight = img.height * scaleFactor;
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        resolve(canvas.toDataURL('image/jpeg', quality));
      };

      img.onerror = () => reject(new Error('Image failed to load'));
      img.src = imagePath;
    });
  };

  const compressImageData = (imageData: string, scaleFactor: number, quality: number): Promise<string> => {
    return processImage(imageData, scaleFactor, quality);
  };

  const renderImages = async (): Promise<void> => {
    const imageKeys = ['happy', 'neutral', 'angry'];
    const numImages = imageKeys.length;
    const totalGapWidth = PDF_CONFIG.imageGap * (numImages - 1);
    const totalWidth = width - PDF_CONFIG.margin.left - PDF_CONFIG.margin.right - totalGapWidth;
    const imageWidth = totalWidth / numImages;
    let currentX = PDF_CONFIG.margin.left;

    for (const key of imageKeys) {
      const imageData = uploadedImages[key];

      if (imageData) {
        const compressedImage = await compressImageData(imageData, 0.5, 0.7);
        doc.addImage(compressedImage, 'JPG', currentX, currentY, imageWidth, imageWidth);
      }

      const emotionText = getEmotionText(key);
      centerTextBelowImage(emotionText, currentX, imageWidth);
      currentX += imageWidth + PDF_CONFIG.imageGap;
    }
  };

  const hexToRgb = (hex: string): Types.RGB => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const fullHex = hex.replace(/^#/, '').replace(/^(.)(.)(.)$/, '$1$1$2$2$3$3');
    const bigint = parseInt(fullHex, 16);

    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const resizeAndAddImageToPDF = async (
    imagePath: string,
    imageWidth: number,
    startX: number,
    startY: number
  ): Promise<void> => {
    const compressedImage = await processImage(imagePath, 1, 0.7);
    const img = new Image();
    img.src = compressedImage;

    return new Promise<void>((resolve, reject) => {
      img.onload = () => {
        const aspectRatio = img.height / img.width;
        globalImageHeight = imageWidth * aspectRatio;
        doc.addImage(compressedImage, 'JPG', startX, startY, imageWidth, globalImageHeight);
        resolve();
      };

      img.onerror = () => reject(new Error('Image failed to load'));
    });
  };

  const drawSelectedAreas = (): void => {
    const selectedGenderImageX = PDF_CONFIG.margin.left;
    const selectedGenderImageY = currentY;
    const selectedGenderImageWidth = SELECTED_GENDER_IMAGE_WIDTH;
    const selectedGenderImageHeight = globalImageHeight;

    selectedAreas.forEach(area => {
      const { btnPdfShapeConfig } = area;

      if (btnPdfShapeConfig) {
        let { x, y, w, h, stroke } = btnPdfShapeConfig[selectedGender];

        // Translate shape coordinates to be relative to the image
        x = selectedGenderImageX + x;  // Adjust X coordinate
        y = selectedGenderImageY + y;  // Adjust Y coordinate

        const { r, g, b } = hexToRgb(stroke);

        // Draw the shape if it's within the image
        if (x + w <= selectedGenderImageX + selectedGenderImageWidth && y + h <= selectedGenderImageY + selectedGenderImageHeight) {
          doc.setDrawColor(r, g, b);
          doc.setLineWidth(1);
          doc.roundedRect(x, y, w, h, 2, 2, 'D');
        }
      }
    });
  };

  const setTextStyle = (): void => setFontStyle(PDF_CONFIG.text.color, PDF_CONFIG.text.size, FONT_SATOSHI_LIGHT);

  const addSectionWithItems = (title: string, items: Types.Goal[], yIncrement: number = 15): void => {
    if (items.length > 0) {
      incrementY(yIncrement);
      addSectionTitleWithLine(title, PDF_CONFIG.sectionTitle.x, PDF_CONFIG.sectionTitle.color, PDF_CONFIG.sectionTitle.size);

      items.forEach(item => {
        setTextStyle();
        doc.text(`+ ${item.btnInnerText}`, PDF_CONFIG.sectionTitle.x, currentY);
        incrementY();
      });
    }
  };

  const addBodyText = (): void => {
    setTextStyle();
    doc.text(pdfText.text, PDF_CONFIG.margin.left, currentY, { maxWidth: width - PDF_CONFIG.margin.left - PDF_CONFIG.margin.right });
    incrementY(30);
  };

  const addSelectedGenderImage = async (): Promise<void> => {
    try {
      await resizeAndAddImageToPDF(imagePaths[selectedGender], SELECTED_GENDER_IMAGE_WIDTH, PDF_CONFIG.margin.left, currentY);
    } catch (error) {
      console.error("Error adding image to PDF:", error);
    }
  };

  const addSections = (): void => {
    addSectionWithItems(pdfText.areasTitle, selectedAreas, 0);
    incrementY(15);
    addSectionWithItems(pdfText.goalsTitle, selectedGoals, 15);
  };

  const checkAndRenderEmotionImages = async (): Promise<void> => {
    if (Object.values(uploadedImages).some(value => value)) {
      addTitle(pdfText.emotionsTitle, PDF_CONFIG.margin.left + 30, PDF_CONFIG.sectionTitle.color, 18); // + 30 to increase the margin
      incrementY(20);
      await renderImages();
    }
  };

  const createHeader = (): void => {
    addBackground(0, 0, PDF_CONFIG.headerHeight);
    incrementY(PDF_CONFIG.headerHeight / 2 + 5); // + 5 to vertically center the text in the header
    addTitle(pdfText.title, width / 2, PDF_CONFIG.title.color, PDF_CONFIG.title.size, { align: 'center' });
    incrementY(40);
  };

  const createBody = async (): Promise<void> => {
    incrementY(15)
    addBodyText();
    await addSelectedGenderImage();
    drawSelectedAreas();
    addSections();
    currentY = 360; //  Always display the images from this coordinate
    await checkAndRenderEmotionImages();
  };

  const createFooter = (): void => {
    const footerImage = '/content/dam/juvederm-ous/fr/consultation-wizard-footer.jpg';
    doc.addImage(footerImage, 0, height - PDF_CONFIG.footerHeight, width, PDF_CONFIG.footerHeight);
  };

  setupFonts();
  createHeader();
  await createBody();
  createFooter();
  doc.save('ma-consultation.pdf');
};
