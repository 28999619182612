export const topicMenuInit = () => {
  const topicMenu = document.querySelector(
    '.juvederm-topicmenu'
  ) as HTMLDivElement;
  const activeItemClass = 'emu-navigation__item--active';
  let topicMenuNavigation,
    sections,
    anchors: HTMLAnchorElement[] = [],
    header,
    headerHeight = 91, // default desktop header height
    topicMenuHeight = 63, // default desktop topic menu height
    pageOffsetTop = headerHeight + topicMenuHeight;

  const updatePageOffsetHeight = () => {
    if (header) {
      headerHeight = header.offsetHeight;
    }

    if (topicMenuNavigation) {
      topicMenuHeight = topicMenuNavigation.offsetHeight;
    }

    pageOffsetTop = headerHeight + topicMenuHeight;
  };

  if (topicMenu) {
    topicMenuNavigation = topicMenu.querySelector(
      '.emu-navigation'
    ) as HTMLElement;
    sections = document.querySelectorAll('.qa-section');
    header = document.querySelector('header.header') as HTMLElement;
    updatePageOffsetHeight();
    // making sure that the resize triggers recalculation of offset height
    window.addEventListener('resize', updatePageOffsetHeight);

    // getting all the anchors that has # in href. Applicable only when topic menu is present on the page
    const allAnchors = document.querySelectorAll(
      'a[href*="#"]'
    ) as NodeListOf<HTMLAnchorElement>;
    if (allAnchors?.length) {
      // adding the anchors that has a corresponding element with ID on the page
      allAnchors.forEach(anchor => {
        const href = anchor.getAttribute('href');
        const id = href?.split('#')[1];
        if (id && document.querySelector(`#${id}`)) {
          anchors.push(anchor);
        }
      });
    }
  }

  const scrollToSection = anchor => {
    const anchorHash = anchor.hash;
    const section = document.querySelector(anchorHash);
    const sectionGap = 5;
    const sectionOffsetTop =
      section.getBoundingClientRect().top +
      window.scrollY -
      pageOffsetTop +
      sectionGap;

    window.scrollTo({
      top: sectionOffsetTop,
      behavior: 'smooth',
    });
  };

  const handleAnchorClick = anchors => {
    anchors.forEach(anchor => {
      anchor.addEventListener('click', e => {
        e.preventDefault();
        anchor.parentElement.classList.remove(activeItemClass);
        scrollToSection(anchor);
      });
    });
  };

  const getAnchorId = anchor => {
    return anchor.hash.replace('#', '');
  };

  const handleActiveMenuItem = () => {
    let current;

    sections.forEach(section => {
      const sectionTop = (section as HTMLElement).offsetTop;

      if (window.scrollY >= sectionTop - pageOffsetTop) {
        current = section.getAttribute('id');
      }
    });

    anchors.forEach(anchor => {
      const anchorId = getAnchorId(anchor);
      const listItem = anchor.parentElement as HTMLLIElement;

      listItem.classList.remove(activeItemClass);

      if (anchorId === current) {
        listItem.classList.add(activeItemClass);
      }
    });
  };

  if (topicMenu) {
    const topicMenuWrapper = topicMenu.parentElement as HTMLDivElement;
    topicMenuWrapper.classList.add('juvederm-topicmenu__wrapper');
    window.addEventListener('scroll', handleActiveMenuItem);
    handleAnchorClick(anchors);
  }
};
