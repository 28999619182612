export const mediaTextInit = () => {
  const mediaTextComponents = document.querySelectorAll<HTMLDivElement>(
    '.juvederm-mediatext'
  );
  if (mediaTextComponents) {
    mediaTextComponents.forEach(mediaTextComponent => {
      const highlightedText =
      mediaTextComponent.querySelectorAll<HTMLDivElement>('.juvederm-mediatext .text .text-highlighted');
      highlightedText.forEach(text => {
        text.parentElement?.classList.add('juvederm-mediatext__textwrapper');
      });
    });
  }
};
