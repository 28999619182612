// Stylesheets
import './phase1-main.scss';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed 
    _badgerInstances: any; //accordion
  }
}

const qsAll = (sel: string): NodeList => document.querySelectorAll(sel);
const qs = (sel: string): HTMLElement | null => document.querySelector(sel);
const DOM_SELECTORS = {
  mapContainer: '.emu-image-map__map-marker-container',
  treatmentAreas: '.juvp1-treatment-areas__items',
  treatmentCarousel: '.juvp1-treatments__carousel',
  topPart: '.juvp1-top-part',
};

function setActiveTreatmentAreas (id) {
  const container = document.getElementById(id)?.parentElement;
  const idSplit = id.split('-');
  const indexNum = idSplit[idSplit.length - 1];
  const contentContainer = container?.closest('.aaaem-tabs__tabpanel');

  container?.querySelectorAll('button').forEach(i => {
    i.setAttribute('aria-pressed', 'false');

    contentContainer?.querySelectorAll(`${DOM_SELECTORS.treatmentAreas} > .text`).forEach(i => {
      i.setAttribute('aria-expanded', 'false');
    })

    if (i.id === id) {
      i.setAttribute('aria-pressed', 'true');
    }

    contentContainer?.querySelector(`#phase-1-treatment-areas-content-${indexNum}`)?.parentElement?.setAttribute('aria-expanded', 'true')
  })
};

function phase1ModalHandler (isOpen, id) { 
  if (qs(`[data-id="${id}"]`)) { 
    const modal = qs(`[data-id="${id}"]`);
    const modalWrapper = modal?.querySelector('.modal-wrapper');
    const duration = isOpen ? 1 : 500;
    const classHandler = (addClass, removeClass) => {
      if (isOpen) {
        modalWrapper?.classList.add(addClass);
      } else {
        modalWrapper?.classList.remove(removeClass);
      }
    };

    classHandler('active', 'visible');

    const classTimer = setTimeout(() => {
      classHandler('visible', 'active');
      clearTimeout(classTimer);
    }, duration);
  }
}

function adjustHeroHeight() {
  const topPartAdjacent = qs(`${DOM_SELECTORS.topPart}`)?.closest('div.container')?.nextElementSibling?.querySelector('[data-component="container"]') as HTMLDivElement;
  const topPartText = qs(`${DOM_SELECTORS.topPart} .juvp1-top-part__text`);
  const topPartImage = qs(`${DOM_SELECTORS.topPart} .juvp1-image__desktop img`) as HTMLImageElement;
  let resizeTimer;

  const adjustPosition = () => {
    const textHeight = topPartText?.offsetHeight;
    const setMargin = () => {
      const imageHeight = topPartImage.offsetHeight;
      topPartAdjacent.style.marginTop = window.innerWidth < 768 ? '' : `-${(imageHeight - (textHeight || 0)) / 2}px`;
    }
    
    if (topPartImage.complete) {
      setMargin()
    } else {
      topPartImage?.addEventListener('load', () => {
        setMargin();
      })
    }
  };

  if (topPartImage && topPartText && topPartAdjacent) {
    adjustPosition();
  }

  window.addEventListener('resize', () => {
    if (resizeTimer) {
      window.cancelAnimationFrame(resizeTimer);
    }

    resizeTimer = window.requestAnimationFrame(() => {
      adjustPosition();
    });
  }, false);
}

function adjustCarouselHeight() {
  const carouselActions = qs('.aaaem-carousel__actions') as HTMLElement;
  const firstImage = qs(`${DOM_SELECTORS.treatmentCarousel} img`) as HTMLImageElement;
  let resizeTimer;

  const setHeight = () => {
    carouselActions.style.top = `${qs('.juvp1-treatments__carousel--image-comparison')?.offsetHeight}px`;
  };

  if (firstImage && firstImage.complete) {
    setHeight();
  } else {
    if (!firstImage) {
      return;
    }

    firstImage.addEventListener('load', () => {
      setHeight();
    })
  }

  window.addEventListener('resize', () => {
    if (resizeTimer) {
      window.cancelAnimationFrame(resizeTimer);
    }

    resizeTimer = window.requestAnimationFrame(() => {
      setHeight();
    });
  }, false);
}

function setDefaultTreatmentAreas() {
  if (qs(DOM_SELECTORS.mapContainer)) {
    const timer = setTimeout(() => {
      qsAll(`${DOM_SELECTORS.mapContainer} button:first-child`).forEach(item => {
        const i = item as HTMLDivElement;
        i.setAttribute('aria-pressed', 'true');
      });
      clearTimeout(timer);
    });
    
    qsAll(`${DOM_SELECTORS.treatmentAreas} > .text`).forEach(item => {
      const i = item as HTMLDivElement;
      const textContainer = i.querySelector('.juvp1-treatment-areas__item');

      i.setAttribute('aria-expanded', 'false');
      
      if (textContainer?.id.includes('0')) {
        i.setAttribute('aria-expanded', 'true');
      }
    })
  }
}

function bindEvents() {
  qsAll('.juvp1-clinic-module-target').forEach(link => {
    link.addEventListener('click', (e) => {
      if (qs('#juvp1-clinic-module')) {
        e.preventDefault();
        qs('#juvp1-clinic-module')?.scrollIntoView({ 
          behavior: 'smooth' 
        });
      }
    });
  });
}

export function phase1Init() {
  if (qs('.juvederm-phase1__clinic')) {
    const timer = setInterval(() => {
      const clinicImage = qs('.icl-detail__header .icl-detail__image') as HTMLImageElement;
      const iclDetails = qs('.icl-detail__container') as HTMLDivElement;

      if (clinicImage && iclDetails) {
        document.documentElement.classList.add('js-clinic-has-image');
        clearInterval(timer);
      }

      if (iclDetails) {
        clearInterval(timer);
      }
    }, 800);
  }

  if (qs('.juvederm-phase1__fac')) {
    const locationServices = qs('.locationservices') as HTMLDivElement;
    const container = locationServices.previousElementSibling?.querySelector('.juvp1-top-part__text');

    container?.appendChild(locationServices);
    locationServices.style.display = 'block';
  }

  if (qs('#juvederm-phase1')) {
    bindEvents();
    setDefaultTreatmentAreas();

    const timer = setTimeout(() => {
      adjustCarouselHeight();
      adjustHeroHeight();
      clearTimeout(timer);
    });
    
    if (window._tnsInstances && window._tnsInstances.juvp1TreatmentsCarousel) {
      const carouselEvents = window._tnsInstances.juvp1TreatmentsCarousel.events;
      const carousel = qs(DOM_SELECTORS.treatmentCarousel);

      carouselEvents.on('transitionStart', () => {
        carousel?.classList.add('js-slide-change-start')
      })

      carouselEvents.on('transitionEnd', () => {
        carousel?.classList.remove('js-slide-change-start')
      })
    }

    if (window.Bus) {
      window.Bus.on('emu-image-map:areaClick', ({id}) => {
        setActiveTreatmentAreas(id);
      })
  
      window.Bus.on('emu-modal:open', ({ id }) => {
        phase1ModalHandler(true, id);
      });
  
      window.Bus.on('emu-modal:close', ({ id }) => {
        phase1ModalHandler(false, id);
      });
    }
  }
}
