export enum Gender {
  Female = 'female',
  Male = 'male'
}

interface GenderSpecificConfig {
  x: number;
  y: number;
  w: number;
  h: number;
  stroke: string;
}

export interface ShapeConfig {
  female: GenderSpecificConfig;
  male: GenderSpecificConfig;
}

export interface AreaSelection {
  btnId: string;
  btnInnerText: string;
  btnPdfShapeConfig?: ShapeConfig;
}

export interface GoalSelection {
  btnInnerText: string;
}

export interface UploadedImages {
  [emotion: string]: string;
}

export interface ControlButtons {
  prev: HTMLButtonElement;
  next: HTMLButtonElement;
  download: HTMLButtonElement;
}

export interface GenderButtons {
  female: HTMLButtonElement;
  male: HTMLButtonElement;
}

export type ButtonHandler = (event: MouseEvent) => void;
