export interface Event {
    element: any;
    address: string; 
    coords: { 
        latitude: number; 
        longitude: number; 
    }
}

export enum STATE_CLASSES {
    ANIMATION = "animate",
}

export enum BUS_EVENTS {
    CHANGE_SELECT = 'emu-location-services:locationSelect',
    CLICK_BUTTON = 'emu-location-services:submitSearch',
    PROMPT_SUSSCESS = 'emu-location-services:promptSuccess',
    CHANGE_COUNTRY = 'emu-location-services:countrySelect',
}

export type ToogleLocationElements = (a: boolean, b?: Array<HTMLElement>) => void;
export type Redirect = (a: string, b:string) => void | boolean;