export const treatmentAreasInit = () => {
  const component = document.querySelectorAll<HTMLDivElement>(
    '.juvederm-treatmentareas'
  );
  component.forEach(component => {
    if (!component) {
      return;
    }

    const extractRotationTimeFromClassName = (element: Element & { className: string }): number => {
      const classNames = element.className;
      const regex = /js-time-(\d+)/;
      const matches = classNames.match(regex);

      if (matches && matches.length > 1) {
        return parseInt(matches[1], 10);
      } else {
        return 7000; // Default rotation time if no matching pattern is found
      }
    };

    const rotationTime = extractRotationTimeFromClassName(component);

    let slideIndexFemale = 1;
    let slideIndexMale = 1;
    const text = component.querySelectorAll<HTMLDivElement>(
      '.aaaem-tabs__tabpanels .aaaem-tabs__tabpanel .container.responsivegrid .aaaem-container.cmp-container .container.responsivegrid'
    );
    const textArray = Array.from(text);
    const slidesFemale = textArray.slice(0, 4);
    const slidesMale = textArray.slice(4);
    const markersArray = Array.from(
      component.querySelectorAll<HTMLButtonElement>(
        '.image button.emu-mapping-marker'
      )
    );
    const femaleMarkers = markersArray.slice(0, 4);
    const maleMarkers = markersArray.slice(4);
    const femaleAnimationSpans = component.querySelectorAll<HTMLButtonElement>(
      '.image:first-child button.emu-mapping-marker span'
    );
    const maleAnimationSpans = component.querySelectorAll<HTMLButtonElement>(
      '.image:nth-child(2) button.emu-mapping-marker span'
    );
    // creating span elements for rotate animation at markers and appending them to wrapper
    const leftHalfCircle = document.createElement('span');
    const rightHalfCircle = document.createElement('span');
    leftHalfCircle.setAttribute('class', 'rotateLeft');
    rightHalfCircle.setAttribute('class', 'rotateRight');
    femaleAnimationSpans.forEach(span => {
      span.setAttribute('class', 'circle-wrapper');
      span.appendChild(leftHalfCircle.cloneNode(true));
      span.appendChild(rightHalfCircle.cloneNode(true));
    });
    maleAnimationSpans.forEach(span => {
      span.setAttribute('class', 'circle-wrapper');
      span.appendChild(leftHalfCircle.cloneNode(true));
      span.appendChild(rightHalfCircle.cloneNode(true));
    });
    const image = component.querySelector(
      '.tabs .aaaem-tabs__tabpanels .aaaem-tabs__tabpanel .container.responsivegrid .aaaem-container.cmp-container .image'
    ) as HTMLDivElement;
    const tabs = component.querySelector(
      '.tabs ul.aaaem-tabs__tablist'
    ) as HTMLUListElement;
    const positionTabs = () => {
      const imageHeight = image.clientHeight;
      const imageWidth = image.clientWidth;
      tabs.style.top = imageHeight - 40 + 'px';
      tabs.style.left = (imageWidth - tabs.clientWidth) / 2 + 'px';
    };
    positionTabs();
    window.addEventListener('resize', () => {
      positionTabs();
    });
    const treatmentAreas = (slides: any[], markers: any[], slideIndex) => {
      let intervalId;

      const plusSlides = n => {
        showSlides((slideIndex += n));
      };
      const currentSlide = n => {
        clearInterval(intervalId);
        showSlides((slideIndex = n));
        intervalId = setInterval(() => {
          plusSlides(1);
        }, rotationTime);
      };
      const showSlides = n => {
        if (n > slides.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = slides.length;
        }
        slides.forEach(slide => slide?.classList.remove('visible'));
        markers.forEach(marker => {
          marker?.classList.remove('overlay');
          marker?.classList.remove('active');
        });
        slides[slideIndex - 1]?.classList.add('visible');
        markers[slideIndex - 1]?.classList.add('overlay');
        markers[slideIndex]?.classList.add('active');
      };
      showSlides(slideIndex);
      markers.forEach((dot, i) => {
        dot.addEventListener('click', () => {
          const imageID = dot.id.split('-mapping-marker-')[0];
          const area = document.querySelectorAll(`#${imageID} area`)[i] as HTMLElement;
          currentSlide(i + 1);
          window.dataLayer.push({
            event: 'facePinSelector',
            faceArea: area.dataset.eventName,
          });
        });
      });

      intervalId = setInterval(() => {
        plusSlides(1);
      }, rotationTime);
    };
    treatmentAreas(slidesFemale, femaleMarkers, slideIndexFemale);
    treatmentAreas(slidesMale, maleMarkers, slideIndexMale);
  });
};
