import { createPdf } from './createPdf';
import * as Types from './consultationWizard.types';

export const consultationWizard = (): void => {
  const wizardContainer = document.querySelector<HTMLDivElement>('.c-consultation-wizard');
  if (!wizardContainer) return;

  const setupDOMElements = (container: HTMLDivElement) => ({
    progressBarContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__progress')!,
    stepContainers: Array.from(container.querySelectorAll<HTMLDivElement>('.c-consultation-wizard__step'))!,
    controlButtons: {
      prev: container.querySelector<HTMLButtonElement>('.c-consultation-wizard__button-prev')!,
      next: container.querySelector<HTMLButtonElement>('.c-consultation-wizard__button-next')!,
      download: container.querySelector<HTMLButtonElement>('.c-consultation-wizard__button-download')!,
    },
    stepTwoContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__step-two')!,
    genderButtons: {
      female: container.querySelector<HTMLButtonElement>('.c-consultation-wizard__button-female')!,
      male: container.querySelector<HTMLButtonElement>('.c-consultation-wizard__button-male')!,
    },
    areaButtons: Array.from(container.querySelectorAll<HTMLButtonElement>('.c-consultation-wizard__button-area'))!,
    goalButtons: Array.from(container.querySelectorAll<HTMLButtonElement>('.c-consultation-wizard__button-goal'))!,
    modalTrigger: container.querySelector<HTMLButtonElement>('.c-consultation-wizard__modal-trigger')!,
    happyContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__container-happy')!,
    neutralContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__container-neutral')!,
    angryContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__container-angry')!,
    happyText: container.querySelector<HTMLDivElement>('.c-consultation-wizard__text-happy')!,
    neutralText: container.querySelector<HTMLDivElement>('.c-consultation-wizard__text-neutral')!,
    angryText: container.querySelector<HTMLDivElement>('.c-consultation-wizard__text-angry')!,
    stepTwoSelectionsContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__container-selections-step2')!,
    stepThreeSelectionsContainer: container.querySelector<HTMLDivElement>('.c-consultation-wizard__container-selections-step3')!
  });

  const domElements = setupDOMElements(wizardContainer);

  let selectedGender: Types.Gender;
  let selectedAreas: Types.AreaSelection[] = [];
  let selectedGoals: Types.GoalSelection[] = [];
  let uploadedImages: Types.UploadedImages = {};

  domElements.controlButtons.next.disabled = true;

  const isAnyToggleButtonOn = (): boolean => {
    return domElements.stepTwoContainer.querySelector('.js-toggle-on') !== null;
  };

  const updateButtonVisibility = (currentStepIndex: number): void => {
    const isLastStep = currentStepIndex === domElements.stepContainers.length - 1;
    domElements.controlButtons.prev.style.display = currentStepIndex === 0 ? 'none' : '';
    domElements.controlButtons.next.style.display = isLastStep ? 'none' : '';
    domElements.controlButtons.download.style.display = isLastStep ? '' : 'none';
    domElements.controlButtons.next.disabled = (currentStepIndex === 0 && !selectedGender) || (currentStepIndex === 1 && !isAnyToggleButtonOn());
  };

  const updateStep = (newStepIndex: number): void => {
    domElements.stepContainers.forEach((container, index) => {
      container.classList.toggle('c-consultation-wizard__step--active', index === newStepIndex);
    });

    domElements.progressBarContainer.querySelectorAll('.progressbar__step').forEach((step, index) => {
      step.classList.toggle('progressbar__step--active', index === newStepIndex);
    });

    updateButtonVisibility(newStepIndex);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const updateGenderActiveClass = (gender: 'female' | 'male'): void => {
    domElements.genderButtons.female.classList.toggle('c-consultation-wizard__button-female--active', gender === Types.Gender.Female);
    domElements.genderButtons.male.classList.toggle('c-consultation-wizard__button-male--active', gender === Types.Gender.Male);
  };

  const addSelectedOptions = (selectedOptions: Types.GoalSelection[], container: HTMLDivElement): void => {
    container.innerHTML = '';
    const fragment = document.createDocumentFragment();

    selectedOptions.forEach(option => {
      const span = document.createElement('span');
      span.className = 'c-consultation-wizard__selection';
      span.innerText = option.btnInnerText;
      fragment.appendChild(span);
    });

    container.appendChild(fragment);
  };

  const getCurrentStepIndex = (): number => {
    return domElements.stepContainers.findIndex(step =>
      step.classList.contains('c-consultation-wizard__step--active')
    );
  };

  const goToPreviousStep = (): void => {
    const activeStepIndex = getCurrentStepIndex();

    if (activeStepIndex > 0) {
      updateStep(activeStepIndex - 1);
    }
  };

  const goToNextStep = (): void => {
    const activeStepIndex = getCurrentStepIndex();

    if (activeStepIndex === 1 && selectedAreas.length > 3) {
      showErrorModal(); 
      return;
    }

    if (activeStepIndex === 2) {
      addSelectedOptions(selectedAreas, domElements.stepTwoSelectionsContainer);
      addSelectedOptions(selectedGoals, domElements.stepThreeSelectionsContainer);
    }

    if (activeStepIndex < domElements.stepContainers.length - 1) {
      updateStep(activeStepIndex + 1);
    }
  };

  const checkToggleButtonsAndSetNext = (currentStepIndex: number): void => {
    if (currentStepIndex === 1) {
      domElements.controlButtons.next.disabled = !isAnyToggleButtonOn();
    }
  };

  const extractPdfShapeConfigText = (button: HTMLButtonElement): string | undefined => {
    const pdfShapeConfigSpan = button.querySelector<HTMLSpanElement>('.cmp-button__icon-text');
    return pdfShapeConfigSpan ? pdfShapeConfigSpan.innerText.trim() : undefined;
  };

  const parseShapeConfig = (json: string): Types.ShapeConfig | undefined => {
    try {
      return JSON.parse(json);
    } catch (error) {
      console.error('Error parsing the shape config:', error);
      return undefined;
    }
  };

  const updateSelectedAreasButtons = (
    selectedButtons: { btnId: string; btnInnerText: string; btnPdfShapeConfig?: Types.ShapeConfig }[],
    clickedButton: HTMLButtonElement
  ): { btnId: string; btnInnerText: string; btnPdfShapeConfig?: Types.ShapeConfig }[] => {
    const isButtonToggledOn = clickedButton.classList.contains('js-toggle-on');
    const pdfShapeConfigText = extractPdfShapeConfigText(clickedButton);

    if (isButtonToggledOn) {
      const pdfShapeConfig = pdfShapeConfigText ? parseShapeConfig(pdfShapeConfigText) : undefined;
      const existingButtonIndex = selectedButtons.findIndex(btn => btn.btnId === clickedButton.id);

      if (existingButtonIndex !== -1) {
        selectedButtons[existingButtonIndex].btnPdfShapeConfig = pdfShapeConfig;
      } else {
        selectedButtons.push({
          btnId: clickedButton.id,
          btnInnerText: clickedButton.innerText.trim(),
          btnPdfShapeConfig: pdfShapeConfig
        });
      }
    } else {
      return selectedButtons.filter(btn => btn.btnId !== clickedButton.id);
    }

    return selectedButtons;
  };

  const updateSelectedGoalsButtons = (buttons: HTMLButtonElement[]): { btnInnerText: string; }[] => {
    return buttons
      .filter(btn => btn.classList.contains('js-toggle-on'))
      .map(btn => ({ btnInnerText: btn.innerText.trim() }));
  };

  const handleAreaButtonClick = (event: MouseEvent): void => {
    const button = (event.target as HTMLElement).closest('button') as HTMLButtonElement;
    if (!button) return;
    selectedAreas = updateSelectedAreasButtons(selectedAreas, button);

    const currentStepIndex = getCurrentStepIndex();
    checkToggleButtonsAndSetNext(currentStepIndex);

    const numberOfSelectedAreas = selectedAreas.length;

    if (currentStepIndex === 1 && numberOfSelectedAreas > 3) {
      showErrorModal();
    }
  };

  const handleGoalButtonClick = (): void => {
    selectedGoals = updateSelectedGoalsButtons(domElements.goalButtons);
  };

  const handleGenderButtonClick = (event: MouseEvent): void => {
    const button = (event.target as HTMLElement).closest<HTMLButtonElement>('.c-consultation-wizard__button-female, .c-consultation-wizard__button-male');
    if (!button) return;
    selectedGender = button.classList.contains('c-consultation-wizard__button-female') ? Types.Gender.Female : Types.Gender.Male;
    updateGenderActiveClass(selectedGender);
    updateButtonVisibility(0);
  };

  const showErrorModal = (): void => {
    domElements.modalTrigger.click();
  };

  const buildProgressBar = (): void => {
    const fragment = document.createDocumentFragment();
    const progressBarBase = document.createElement('div');
    progressBarBase.className = 'progressbar__base';

    for (let index = 0; index < domElements.stepContainers.length; index++) {
      const isActiveClass = index === 0 ? 'progressbar__step--active' : '';
      const stepNumber = index + 1;
      const stepDiv = document.createElement('div');
      stepDiv.className = `progressbar__step ${isActiveClass}`;
      const stepNumberSpan = document.createElement('span');
      stepNumberSpan.className = 'progressbar__step-number';
      stepNumberSpan.textContent = stepNumber.toString();
      stepDiv.appendChild(stepNumberSpan);
      progressBarBase.appendChild(stepDiv);
    }

    fragment.appendChild(progressBarBase);
    domElements.progressBarContainer.appendChild(fragment);
  };

  const readAsDataURL = (file: File): Promise<string> =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  
  const cropImageToSquare = (base64String: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const minDimension = Math.min(img.width, img.height);
        const canvas = document.createElement('canvas');
        canvas.width = minDimension;
        canvas.height = minDimension;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          const startX = (img.width - minDimension) / 2;
          const startY = (img.height - minDimension) / 2;
          ctx.drawImage(img, startX, startY, minDimension, minDimension, 0, 0, minDimension, minDimension);
          resolve(canvas.toDataURL());
        } else {
          reject(new Error('Canvas context is not available'));
        }
      };

      img.onerror = () => reject(new Error('Image loading failed'));
      img.src = base64String;
    });
  };

  const updateLabelBackground = (labelElement: HTMLLabelElement, base64String: string): void => {
    labelElement.style.backgroundImage = `url('${base64String}')`;
    labelElement.style.fontSize = '0';
  };

  const storeFile = (emotion: string, base64String: string): void => {
    uploadedImages[emotion] = base64String;
  };

  const handleFileChange = async (inputElement: HTMLInputElement, labelElement: HTMLLabelElement, emotion: string): Promise<void> => {
    if (!inputElement.files?.[0]) return;

    const file = inputElement.files[0];
    try {
      const base64String = await readAsDataURL(file);
      const croppedImageBase64 = await cropImageToSquare(base64String);
      updateLabelBackground(labelElement, croppedImageBase64);
      storeFile(emotion, croppedImageBase64);
    } catch (error) {
      console.error('Error processing file for emotion:', emotion, error);
    }
  };

  const createLabel = (emotion: string, labelText: string): HTMLLabelElement => {
    const labelElement = document.createElement('label');
    labelElement.id = `label-for-${emotion}`;
    labelElement.htmlFor = `photo-upload-${emotion}`;
    labelElement.className = 'c-consultation-wizard__label';
    labelElement.textContent = labelText;
    return labelElement;
  };

  const createInput = (emotion: string): HTMLInputElement => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.id = `photo-upload-${emotion}`;
    inputElement.name = `photo-${emotion}`;
    inputElement.className = `c-consultation-wizard__input c-consultation-wizard__input-${emotion}`;
    inputElement.accept = 'image/png, image/jpeg';
    inputElement.setAttribute('aria-describedby', `instructions-${emotion}`);
    inputElement.setAttribute('capture', 'camera');
    return inputElement;
  };

  const attachFileChangeListener = (inputElement: HTMLInputElement, labelElement: HTMLLabelElement, emotion: string): void => {
    inputElement.addEventListener('change', () => handleFileChange(inputElement, labelElement, emotion));
  };

  const buildImageUploadForm = (container: HTMLDivElement, labelContainer: HTMLDivElement, emotion: string): void => {
    const labelTextElement = labelContainer.querySelector('p');
    if (!labelTextElement) {
      console.error('Label text element not found');
      return;
    }

    const fragment = document.createDocumentFragment();
    const form = document.createElement('form');
    form.className = 'c-consultation-wizard__form';

    const labelText = labelTextElement.innerText;
    const labelElement = createLabel(emotion, labelText);
    const inputElement = createInput(emotion);

    attachFileChangeListener(inputElement, labelElement, emotion);

    form.appendChild(labelElement);
    form.appendChild(inputElement);
    fragment.appendChild(form);
    container.appendChild(fragment);
  };

  const handleDownloadButtonClick = (): void => {
    createPdf(selectedAreas, selectedGoals, uploadedImages, selectedGender);
  };

  const buttonHandlers: {
    [buttonClass: string]: (event: MouseEvent) => void;
  } = {
    'c-consultation-wizard__button-area': handleAreaButtonClick,
    'c-consultation-wizard__button-goal': handleGoalButtonClick,
    'c-consultation-wizard__button-female': handleGenderButtonClick,
    'c-consultation-wizard__button-male': handleGenderButtonClick,
    'c-consultation-wizard__button-prev': goToPreviousStep,
    'c-consultation-wizard__button-next': goToNextStep,
    'c-consultation-wizard__button-download': handleDownloadButtonClick
  };

  const handleButtonClick: Types.ButtonHandler = (event) => {
    const target = event.target as HTMLElement;
    const button = target.closest('button') as HTMLButtonElement;

    if (!button) return;

    const handlerKey = Array.from(button.classList).find(cls => buttonHandlers[cls]);
    if (handlerKey) {
      buttonHandlers[handlerKey](event);
      button.blur();
    }
  };

  buildProgressBar();
  buildImageUploadForm(domElements.happyContainer, domElements.happyText, 'happy');
  buildImageUploadForm(domElements.neutralContainer, domElements.neutralText, 'neutral');
  buildImageUploadForm(domElements.angryContainer, domElements.angryText, 'angry');
  wizardContainer.addEventListener('click', handleButtonClick);
  updateButtonVisibility(0);
};
