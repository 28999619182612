export const under18HeaderOverrides = () => {
  // This function will scroll to footer on clicking of adverse events link in header
  const handleScrollToFooter = () => {
    const adverseEventLink = document.querySelector(
      '.juvederm-navigation-under18--mobile .emu-navigation__content-wrapper nav a'
    ) as HTMLElement;
    if (adverseEventLink) {
      adverseEventLink.addEventListener('click', e => {
        e.preventDefault();
        const footerEventElement = document.querySelector(
          '.under18-footer-container'
        ) as HTMLElement;

        if (footerEventElement) {
          const sectionOffsetTop =
            footerEventElement.getBoundingClientRect().top;

          window.scrollTo({
            top: sectionOffsetTop,
            behavior: 'smooth',
          });
        }
      });
    }
  };
  handleScrollToFooter();
};
